<template>
  <b-form-group
    label="Renk Teması"
    label-for="title"
  >
    <validation-provider
      #default="{ errors }"
      name="Renk Teması"
      rules="required"
    >
      <v-select
        id="theme"
        v-model="itemData.theme"
        :options="dataList"
        :reduce="item => item.value"
        label="title"
        placeholder="Seçiniz"
      />
      <small class="text-danger">{{ errors[0] }}</small>
    </validation-provider>
  </b-form-group>
</template>

<script>
import { ValidationProvider } from 'vee-validate'
import { BFormGroup } from 'bootstrap-vue'
import vSelect from 'vue-select'

export default {
  name: 'ThemeSelect',
  components: {
    BFormGroup,
    ValidationProvider,
    vSelect,
  },
  computed: {
    itemData() {
      return this.$store.getters['privateReports/dataItem']
    },
    dataList() {
      return [
        {
          title: 'Açık Mavi',
          value: 'light',
        },
        {
          title: 'Koyu Mavi',
          value: 'dark',
        },
      ]
    },
  },
}
</script>
