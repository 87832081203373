<template>
  <div>
    <b-tabs
      pills
      fill
    >
      <b-tab active>
        <template #title>
          <feather-icon icon="InfoIcon" />
          <span>Genel Bilgi</span>
        </template>
        <b-card>
          <b-row>
            <b-col cols="12">
              <theme-select />
            </b-col>
            <b-col cols="12">
              <title-text />
            </b-col>
            <b-col cols="12">
              <sub-title-text />
            </b-col>
            <b-col cols="12">
              <summary-content />
            </b-col>
            <b-col cols="12">
              <content-text />
            </b-col>
          </b-row>
        </b-card>
        <image-card />
      </b-tab>
      <b-tab
        :disabled="!dataItem.id"
        lazy
      >
        <template #title>
          <FeatherIcon icon="SettingsIcon" />
          SEO Ayarları
        </template>
        <seo-form
          :item-id="dataItem.id"
          id-components="20"
        />
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
import {
  BCard, BCol, BRow, BTab, BTabs,
} from 'bootstrap-vue'
import ThemeSelect from '@/views/Admin/Private_reports/elements/ThemeSelect.vue'
import TitleText from '@/views/Admin/Private_reports/elements/TitleText.vue'
import SubTitleText from '@/views/Admin/Private_reports/elements/SubTitleText.vue'
import SummaryContent from '@/views/Admin/Private_reports/elements/SummaryContent.vue'
import ContentText from '@/views/Admin/Private_reports/elements/ContentText.vue'
import ImageCard from '@/views/Admin/Private_reports/elements/ImageCard.vue'
import SeoForm from '@/views/Admin/Seo_configs/ItemForm.vue'

export default {
  name: 'ItemForm',
  components: {
    SeoForm,
    BTab,
    BTabs,
    BCard,
    BRow,
    BCol,
    ThemeSelect,
    TitleText,
    SubTitleText,
    SummaryContent,
    ContentText,
    ImageCard,
  },
  computed: {
    dataItem() {
      return this.$store.getters['privateReports/dataItem']
    },
  },
}
</script>
